import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Img from "gatsby-image" // to take image data and render it
import usePositionFooter from "../util/position-footer"

export default function Home({ data, location }) {
  const [copyrightFooterRef, runAfterImagesLoaded] = usePositionFooter(location)

  return (
    <Layout location={location} ref={copyrightFooterRef}>
      <Row>
        <Col style={{ textAlign: "right" }}>
          <Img
            onLoad={runAfterImagesLoaded}
            alt="Lina Janas"
            fixed={data.allFile.edges[0].node.childImageSharp.fixed}
          />
        </Col>
        <Col style={{ textAlign: "left" }}>
          <p>Lina can be reached at linapinart [at] gmail [dot] com</p>
        </Col>
      </Row>
    </Layout>
  )
}

export const query = graphql`
  {
    allFile(
      filter: { sourceInstanceName: { eq: "contact" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            fixed(width: 300) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
